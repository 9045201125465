import React from "react"
import PropTypes from "prop-types"

/**
 * React Wubot component
 * @component
 * @example
 * const pageData = {
 *  seo: {
 *    title: "Page title"
 *  }
 * return (
 *  <WubotComponent />
 * )
 */
const WubotComponent = () => {
  return (
    <div className = "lex-web-ui-iframe lex-web-ui-iframe--hide"
      id = "lex-web-ui-iframe"></div>
  )
}

WubotComponent.propTypes = {
  content: PropTypes.string
}

export default WubotComponent